@font-face {
  font-family: "Montserrat Regular";
  font-display: block;
  src: local("./assets/fonts/Montserrat-Regular.woff"),
    url("./assets/fonts/Montserrat-Regular.woff") format("woff");
  /* url("./assets/fonts/Montserrat-Regular.ttf") format("ttf"); */
}

@font-face {
  font-family: "Montserrat Medium";
  font-display: block;
  src: local("./assets/fonts/Montserrat-Medium.woff"),
    url("./assets/fonts/Montserrat-Medium.woff") format("woff");
  /* url("./assets/fonts/Montserrat-Medium.ttf") format("ttf"); */
}
@font-face {
  font-family: "Montserrat SemiBold";
  font-display: block;
  src: local("./assets/fonts/Montserrat-SemiBold.woff"),
    url("./assets/fonts/Montserrat-SemiBold.woff") format("woff");
  /* url("./assets/fonts/Montserrat-SemiBold.ttf") format("ttf"); */
}
@font-face {
  font-family: "Montserrat Bold";
  font-display: block;
  src: local("./assets/fonts/Montserrat-Bold.woff"),
    url("./assets/fonts/Montserrat-Bold.woff") format("woff");
  /* url("./assets/fonts/Montserrat-Bold.ttf") format("ttf"); */
}

@font-face {
  font-family: "Montserrat Black";
  font-display: block;
  src: local("./assets/fonts/Montserrat-Black.woff"),
    url("./assets/fonts/Montserrat-Black.woff") format("woff");
  /* url("./assets/fonts/Montserrat-Black.ttf") format("ttf"); */
}
