@import "../../../node_modules/bootstrap/scss/bootstrap";
.text-select {
  user-select: text !important;
}
.text-bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.color-gray-dark {
  color: var(--gray-dark);
}
.color-gray-dim {
  color: #777;
}
.color-gray-bright {
  color: #ccc;
}
.color-success {
  color: #00af91;
}
.color-danger {
  color: #ef476f;
}
.color-purple {
  color: #845ec2;
}
.color-lightBlue {
  color: #00b8d9;
}
.bg-gray-dim {
  background-color: #777;
}
.icon {
  width: 21px;
  height: 21px;
}
.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.4;
  z-index: 98;
  top: 0;
  left: 0;
}
.nav-tabs .nav-link {
  // width: 150px;
}
.as-main-container.tsg-navbar-sm .sidebar {
  width: 67px;
}
.as-main-container.tsg-navbar-sm:hover .sidebar:hover {
  width: 270px;
}
.as-main-container.tsg-navbar-sm {
  width: calc(100% - 67px);
  left: 67px;
}
.as-main-container.tsg-navbar-sm .header {
  width: calc(100% - 67px);
}
.as-main-container.tsg-navbar-sm .sidebar ul li a span {
  display: none;
  transition: none;
}
.as-main-container.tsg-navbar-sm .sidebar ul .child-li {
  display: none;
  transition: none;
}
.as-main-container.tsg-navbar-sm .sidebar ul .child-li img {
  display: none;
  transition: none;
}
.as-main-container.tsg-navbar-sm .sidebar:hover ul .child-li {
  display: block;
  transition: none;
}
.tsg-navbar-sm .sidebar:hover ul li a span {
  display: block;
}
.as-main-container.tsg-navbar-lg .sidebar ul li a span {
  display: block;
  transition: none;
}

.as-main-container {
  height: 100%;
  width: calc(100% - 270px);
  position: absolute;
  left: 270px;
}
.as-main-container .content {
  margin-top: 83px;
  overflow-y: auto;
  height: calc(100vh - 83px);
}
.as-main-container .content-wrapper .card-body {
  padding: 0;
}

.graph-header .trend {
  font-size: 19px;
  font-weight: bold;
}
.graph-header .date,
.graph-header .duration {
  color: var(--gray-light);
  margin-top: 5px;
  font-size: 12px;
}
.graph-header .duration {
  padding-left: 35px;
  padding-right: 10px;
}
.graph-header .duration::before {
  content: "";
  position: absolute;
  width: 25px;
  left: 0;
  top: 12px;
}
.graph-header .duration.daily::before {
  border: 2px solid #3751ff;
}
.graph-header .duration.weekly::before {
  border: 2px solid #9fa2b4;
}
.graph-header .duration.monthly::before {
  border: 2px solid rgba(223, 224, 235, 1);
}
.metrics-wrapper {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid gainsboro;
}
.metrics-head {
  font-size: 1rem;
  text-transform: capitalize;
  font-family: "Montserrat medium";
  color: var(--gray-light);
}
.metrics-count {
  font-size: 24px;
  font-family: "Montserrat Bold";
}
.metrics-container {
  border-left: 1px solid gainsboro;
}
.stats-wrapper {
  padding: 1.25rem;
}
.graph-header-duration {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.dropdown-search {
  width: 100%;
  background-color: #1d1d1d;
  border-bottom: 1px solid #333;
  border-right: none;
  border-top: 0;
  height: 35px;
  color: #ccc;
  padding: 0 45px;
  padding-bottom: 5px;
  outline: none;
  margin-bottom: 10px;
}

.dropdown-search-icon {
  position: absolute;
  left: 15px;
  top: 6px;
  filter: brightness(0.5);
}

.dropdown-reset-icon {
  position: absolute;
  right: 15px;
  top: 1px;
  filter: contrast(0);
  z-index: 9;
  cursor: pointer;
}
.dropdown .tsg-dropdown {
  text-align: left;
  background-color: var(--background);
  border-color: #333;
  padding: 8px 18px;
  border-radius: 8px;
  color: #ccc;
  font-family: "Montserrat regular";
  font-size: 16px;
}
.dropdown .dropdown-menu {
  color: #777777;
  background-color: #1d1d1d;
  width: 100%;
  box-shadow: 1px 4px 10px 4px rgb(0 0 0 / 25%);
  border-radius: 12px;
  margin-top: 10px;
  min-width: 340px;
  max-height: 422px;
  overflow-y: auto;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}
.dropdown .dropdown-menu .dropdown-item.active {
  background-color: transparent;
}
.dropdown .dropdown-menu .dropdown-item.filter-parent {
  padding: 10px 20px;
}
.dropdown .dropdown-menu .dropdown-item.filter-parent:not(:last-child) {
  border-bottom: 1px solid #333;
}
.dropdown .dropdown-menu .dropdown-item {
  color: #777777;
}
.dropdown .tsg-dropdown img {
  width: 33px;
}
.dropdown .tsg-dropdown:focus {
  box-shadow: none;
}
.dropdown .tsg-dropdown:hover,
.dropdown .tsg-dropdown:focus,
.dropdown .tsg-dropdown:active {
  color: #ccc;
  background-color: var(--background);
  border-color: #fff;
}
/* Tabs */
.tsg-tab {
  padding: 0.75rem 1.125rem;
  color: #777;
  background: transparent;
  border: 2px solid #333;
  border-radius: 25px;
  cursor: pointer;
  text-transform: capitalize;
  margin-right: 10px;
  font-family: "Montserrat Semibold";
  font-size: 1rem;
}
.tsg-tab.active {
  background-color: #333;
  color: #ccc;
}
.tsg-tab:hover {
  border-color: #ccc;
  outline: thick;
}
/* Graph */
.graph-sm-container {
  margin-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
}
.graph-sm-title {
  font-size: 18px;
  color: #777;
}

.graph-sm-number-sub {
  font-size: 15px;
  color: #ccc;
  font-family: "Montserrat SemiBold";
  margin-top: 2px;
  margin-bottom: 5px;
}
/* Table */
.table {
  border-radius: 10px;
}

/* Badge */
.tsg-badge {
  padding: 4px;
  text-align: center;
  border-radius: 20px;
  font-size: 10px;
  color: #1d1d1d;
  font-family: "Montserrat Medium";
  min-width: 80px;
  margin-top: 2px;
}
.tsg-badge.success {
  background: #00af91;
}
.tsg-badge.danger {
  background: #ef476f;
}
.tsg-badge.info {
  background: #00b8d9;
}
.tsg-badge.warning {
  background: #ffd166;
}

.tsg-bg-purple {
  background-color: #845ec2;
}
.tsg-bg-success {
  background-color: #00af91;
}
.tsg-bg-danger {
  background-color: #ef476f;
}
.tsg-bg-yellow {
  background-color: #ffd166;
}
/* Activiity Log */
.log-container {
  margin-left: 50px;
  margin-top: 30px;
}

.td-danger {
  color: #ef476f !important;
}
/* Page header */
.page-header {
  margin-bottom: 15px;
}
.page-header .title {
  font-size: 20px;
  font-family: "Montserrat SemiBold";
}

/* legend */
.legend-wrapper .legend-title {
  font-size: 12px;
  font-family: "Montserrat SemiBold";
  margin-left: 5px;
  margin-right: 15px;
}
/* Dropdown */
.navbar-dropright .dropdown-menu.show {
  position: fixed !important;
  transform: translate3d(228px, 15px, 0px) !important;
  top: 217px !important;
  background: #333;
}
.navbar-dropright .dropdown-menu a {
  padding: 12px 25px;
  color: #ccc;
}
/* . navbar-dropright .dropdown-menu:hover a  {
    background-color: rgba(0, 0, 0, 0.3);
  } */
.navbar-dropright .dropdown-menu .dropdown-item:focus,
.navbar-dropright .dropdown-menu .dropdown-item:hover {
  background-color: #e9ecef;
  color: #333 !important;
}
.dropright .dropdown-toggle::after {
  display: none;
}
/* Modal */

.modal-content .close {
  color: #000;
}
.modal-content .modal-header {
  border-color: transparent;
}
/* Key Value */
.key-value-wrapper {
  display: flex;
  font-family: "Montserrat Medium";
  margin-bottom: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}
.key-value-wrapper .key {
  color: #777;
  margin-right: 5px;
  font-family: "Montserrat Regular";
}
.grayscale {
  filter: grayscale(1);
  pointer-events: none;
  cursor: not-allowed;
}
.sync-date-time-wrapper {
  margin-right: 30px;
}
.sync-date-time-wrapper img {
  margin-right: 5px;
}
.sync-date-time-wrapper span {
  font-size: 12px;
  font-family: "Montserrat Medium";
}

/* Matching Rules (ATM Modal) */
.matching-rule-item {
  display: inline-flex;
  padding: 5px 10px;
  border-radius: 44px;
  font-size: 12px;
  color: #fff;
}

.react-bootstrap-table {
  overflow-x: auto !important;
}
.no-matches {
  padding: 5px 15px;
}

//style for region dropdown
.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}

.search {
  border: 2px solid #128ab2;
  width: 88%;
  padding: 5px;
  border-radius: 10px;
  margin-left: 10px;
}
.infinite-scroll-component {
  margin: 14px;
}
.tag-list {
  list-style: none;
  color: #0007;
  margin-left: -26px;
  display: none;
}
.tag-item {
  float: left;
  margin: 4px 2px;
  background: #118ab2;
  color: #fff;
  padding: 2px;
  border-radius: 3px;
  font-size: 12px;
}
.tag-remove {
  border: 1px solid #fff;
  border-radius: 6px;
  margin-left: 5px;
}
.toggle,
.expanded {
  padding-right: 5px;
}
.node-label {
  padding-left: 5px;
  font-size: 16px;
}
.parent-expand-bar > td {
  color:"#f4511e";
}

//style for region dropdown ended

.branchMgtFormDiv {
  font-size: "10px";
  font-family: "Montserrat SemiBold";
  margin-bottom: "5";
}

//style for trigger value in scheduler screen
.cron_builder .nav-tabs .nav-item.show .nav-link, .cron_builder .nav-tabs .nav-link.active {
  border-color: #dee2e6 #dee2e6 #fff;
  background-color: #ffffff;
  color: #2c2c2c;
}

.nostro-reconciler-table .gray-row {
  background-color: #f2f2f2 !important; /* Gray background color */
}

.nostro-reconciler-table .white-row {
  background-color: #ffffff !important; /* White background color */
}


@media (max-width: 765px) {
  .graph-header {
    align-items: center;
  }
  .as-main-container {
    left: 0;
    width: 100%;
  }
  .header .overview {
    font-size: 18px;
    margin-right: 4px;
  }
  .header .header-right .noti,
  .header .header-right .search {
    margin-right: 10px;
  }
  .stats-wrapper {
    padding: 5px;
  }
}

@media (max-width: 500px) {
  .tsg-tab {
    font-size: 0.8rem;
  }
}
