.single-info-card-container {
  padding: 15px 25px;
  margin-top: 15px;
  background: #1d1d1d;
  border-radius: 17px;
}
.single-info-card-title {
  font-size: 14px;
  margin-bottom: 15px;
  color: #777;
  font-family: "Montserrat Semibold";
}
.single-info-card-percentage {
  font-size: 41px;
  color: #ccc;
  font-family: "Montserrat Bold";
}
.single-info-card-turnout {
  margin-left: 10px;
}
.single-info-card-turnout img {
  width: 10px;
  margin-right: 4px;
}
.single-info-card-turnout span {
  font-family: "Montserrat Semibold";
  font-size: 14px;
}
