.fancy-scroll::-webkit-scrollbar {
  width: 6px;
}
.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  background-color: #89898961;
}
.fancy-scroll.auto::-webkit-scrollbar-thumb {
  background-color: #89898900;
}
.fancy-scroll.auto:hover::-webkit-scrollbar-thumb {
  background-color: #89898961;
}

.fancy-scroll {
  overflow-y: scroll;
  scrollbar-color: #89898900;
  scrollbar-width: thin;
  user-select: text !important;
}


td.disabled {
  cursor: not-allowed !important;
}
td.disabled div {
  pointer-events: none !important;
}
