 .btn-md {
     width:  '100px';
 }
.login,
.image {
  min-height: 100vh;
}
.bg-image {
  background-image: url('../../assets/images/login-left-bg.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}